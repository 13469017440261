import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { graphql, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import urlJoin from "url-join";

import { Box } from "../components/Primitives";
import Card from "../components/Card";
import SEO from "../components/SEO";

const NotFoundPage = ({
  data: {
    happyPanda: {
      childImageSharp: { fluid },
    },
    site: {
      siteMetadata: { siteUrl, card, title },
    },
  },
}) => (
  <Fragment>
    <SEO
      title={`Page not found | ${title}`}
      description="The page you requested was not found."
      image={urlJoin(siteUrl, card)}
      url={urlJoin(siteUrl, "/404/")}
    />
    <Card>
      <h1>Page not found...</h1>
      <p>
        Someone told you that we have this thing but we obviously
        don&apos;t.{" "}
      </p>
      <p>You have two options:</p>
      <Box as="ul" mb={4}>
        <li>
          You can go back to the
          <Link to="/"> main page </Link>
          and find
          <b> something better. </b>
        </li>
        <li>
          Or you can leave and
          <b> make this panda sad. </b>
        </li>
      </Box>
      <GatsbyImage image={fluid} />
    </Card>
  </Fragment>
);

NotFoundPage.propTypes = {
  data: PropTypes.shape({
    happyPanda: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        // eslint-disable-next-line
        fluid: PropTypes.object.isRequired,
      }).isRequired,
    }).isRequired,
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        siteUrl: PropTypes.string.isRequired,
        card: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
};

export const query = graphql`
  {
    happyPanda: file(relativePath: { regex: "/happy-panda/" }) {
      childImageSharp {
        gatsbyImageData(
          width: 800
          placeholder: TRACED_SVG
          layout: CONSTRAINED
        )
      }
    }
    site {
      siteMetadata {
        siteUrl
        card
        title
      }
    }
  }
`;

export default NotFoundPage;
